input {
  width: 95%;
  height: 90px;
  padding: 15px;
  font-size: 50px;
  font-weight: bolder;
  border: 2px;
  box-sizing: border-box;
  border-color: #159782;
  border-style: solid;
}

.number-input-editable {
  width: 80%;
  height: 80px;
  padding: 15px;
  font-size: 50px;
  font-weight: bolder;
  border: 1px;
  box-sizing: border-box;
  border-color: #FFFFFF;
  border-style: solid;
  letter-spacing: 27px;
  background-image: url("../../../../images/mobile-guide-2.png");
  background-position: 10px 65px;
  background-repeat: no-repeat;
}

.number-input {
  border: 1px solid #FFFFFF;
  width: 50px;
  display: inline-block;
  margin-right: 7px;
  font-size: 50px;
  font-weight: bolder;
  padding: 5px;
  text-align: center;
  height: 80px;
  color: #000000;
}

.simple-keyboard {
  background-color: transparent;
}

.simple-keyboard.my-theme .hg-button.default-button {
  background: #C1C1C1;
  color: #0E2C39;
  width: 100px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif, Medium;
  font-size: 35px;
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.simple-keyboard.my-theme .hg-button.delete-button {
  background: #BA0000;
  color: #FFFFFF;
  width: 425px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 50px;
  margin-right: 20px;
  border-radius: 10px;
}
.simple-keyboard.my-theme .hg-button.next-button {
  background: #39CFBA;
  color: #FFFFFF;
  width: 425px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif, Bold;
  font-size: 50px;
  font-weight: bolder;
  border-radius: 10px;
}
.simple-keyboard.my-theme .hg-button.next-button-disabled {
  background: #C0C0C0 !important;
  color: #FFFFFF;
  width: 425px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif, Bold;
  font-size: 50px;
  font-weight: bolder;
  border-radius: 10px;
}
.simple-keyboard.my-theme .hg-button.default-number-button {
  background: #C1C1C1;
  color: #0E2C39;
  width: 120px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif, Medium;
  font-size: 65px;
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 18px;
}
.simple-keyboard.my-theme .hg-button.delete-number-button {
  background: #BA0000;
  color: #FFFFFF;
  width: 200px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif, Bold;
  font-size: 40px;
  font-weight: bolder;
  border-radius: 10px;
  margin-right: 18px;

}
.simple-keyboard.my-theme .hg-button.next-number-button {
  background: #39CFBA;
  color: #FFFFFF;
  width: 200px;
  height: 80px;
  font-family: Arial, Helvetica, sans-serif, Bold;
  font-size: 45px;
  font-weight: bolder;
  border-radius: 10px;
}

.simple-keyboard.my-theme .hg-button.hide-button1 {
  background: none;
  color: transparent;
  width: 40px;
  height: 80px;
  border: none;
  border-color: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none;
  visibility: hidden;
}

.simple-keyboard.my-theme .hg-button.hide-button2 {
  background: none;
  color: transparent;
  width: 80px;
  height: 80px;
  border: none;
  border-color: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none;
  visibility: hidden;
}

.simple-keyboard.my-theme .hg-button.hide-button3 {
  background: none;
  color: transparent;
  width: 100px;
  height: 80px;
  border: none;
  border-color: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none;
  visibility: hidden;
}
.simple-keyboard.my-theme .hg-button.hide-button4 {
  background: none;
  color: transparent;
  width: 62px;
  height: 80px;
  border: none;
  border-color: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none;
  visibility: hidden;
}

.simple-keyboard.my-theme .hg-button.hide-button5 {
  background: none;
  color: transparent;
  width: 2px;
  height: 80px;
  border: none;
  border-color: none;
  padding: 0;
  font: none;
  cursor: pointer;
  outline: none;
  visibility: hidden;
}

.labelRow {
  margin-bottom: 20px;
}
.labelWrapper {
  text-align: right;
}
.formLabel {
  font-size: 40px;
  font-weight: bold;
  line-height: 25px;
  width: 400px;
  padding-top:10px ;
  padding-bottom: 10px;
}

.formLabelSinhala {
  font-size: 30px;
  font-weight: bold;
  line-height: 25px;
  width: 400px;
  padding-top:10px ;
  padding-bottom: 10px;
}


.input-wrapper {
  width: 100%;
  border: 2px solid #159782;
  padding: 10px;
  font-size: 50px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}


