.form_overlay {
	overflow: hidden;
}

.form_content_box {
	overflow: hidden;
	box-sizing: border-box;
	min-height: 50vh;
}

.fheader {
	display: grid;
	grid-template-columns: auto 2fr auto;

	.poster {
		padding: 0.5em;
		border: 1px dotted #222;

		&.close {
			text-align: center;
		}

		&.title {
			display: flex;
			flex-direction: column;
			align-self: baseline;
			text-align: center;
			font-size: 22px;
			font-family: 'Roboto Condensed', sans-serif;
		}

		&.left_w {
			div {
				border: 1px dotted indianred;
				margin-right: 2em;
			}

			display: inline-grid;
			grid-template-columns: 1fr auto;
		}
	}
}

.step_box {
	&:first-child {
		&:after {
			content: '';
			display: inline-block;
			height: 0.9em;
			vertical-align: middle;
			width: 35px;
			margin-left: 31px;
		}
	}
}

// doctor registration success page :: animation

.timer {
	font-family: $font-bold, sans-serif;
	letter-spacing: 2px;

	color: $default-theme-green !important;

	.type {
		font-size: 18px;
		font-family: $font-light, sans-serif;
		color: #c1c1c1;
		margin-left: -10px;

		@media only screen and (max-width: 425px) {
			font-size: 14px;
		}
	}

	.value {
		font-size: clamp(30px, 4vw, 50px);

		@media only screen and (max-width: 425px) {
			font-size: 30px !important;
		}
	}
}

.search-icon {
	padding: 2rem 0rem 2rem 0rem;

	.search-user-img {
		max-width: 6rem;
		max-height: 6rem;

		@media only screen and (max-width: 768px) {
			width: calc(100% - 80%);
			height: calc(100% - 80%);
		}

		//height: 6rem;
		-webkit-animation: zoomeffect 2s infinite;
		-moz-animation: zoomeffect 2s infinite;
		animation: zoomeffect 2s infinite;

		@-webkit-keyframes zoomeffect {
			0% {
				background-position: center;
				transform: scale(1, 1);
			}

			50% {
				background-position: center;
				transform: scale(1.3, 1.3);
			}

			50% {
				background-position: center;
				transform: scale(1, 1);
			}

			100% {
				background-position: center;
				transform: scale(1, 1);
			}
		}

		@keyframes zoomeffect {
			0% {
				background-position: center;
				transform: scale(1, 1);
			}

			50% {
				background-position: center;
				transform: scale(1.3, 1.3);
			}

			100% {
				background-position: center;
				transform: scale(1, 1);
			}
		}
	}
}

/*
--------------------------------------------------------------
 NIC image upload box styles
 --------------------------------------------------------------
*/

.nicBoxContainerBorder {
	border-right: '1px solid #ddd';
}

.custom-file-upload {
	input[type='file'] {
		display: none;
	}

	margin-bottom: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	// border: 1px dotted #003;
	display: inline-block;
	padding: 5px 5px;
	cursor: pointer;
	width: 100%;
	height: auto;

	.upload_image_span {
		margin: 0 auto;
		background: #ebfffb;
		box-sizing: border-box;
		border: 2px solid $default-theme-green;
		border-radius: 5px;

		.upload_image_caption {
			color: #39cfba;
			padding: 8px 0px;
			font-family: $font-medium, sans-serif !important;
			font-size: clamp(14px, calc(3vw - 0.2rem), 24px);
		}
	}

	.selected_image_box {
		margin: 0 auto;
		background: transparent;
		box-sizing: border-box;
		border: 2px solid $default-theme-green;
		border-radius: 5px;
		object-fit: 'cover';
		background-size: auto;
	}
}

// .nic_file_upload_box {
//     input[type="file"] {
//         display: none;
//     }
// }

/*
--------------------------------------------------------------
 OTP input box styles
 --------------------------------------------------------------
*/

.opt_input_box {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1em 2em;
	//background-color: darkgrey;

	.active-box {
		border-bottom: 4px solid #39cfba !important;
	}

	.inactive-box {
		border-bottom: 4px solid #b3b3b3 !important;
	}

	input {
		&:focus {
			outline: none !important;
		}

		border: none;
		max-width: 50px;
		height: 50px !important;
		margin: 0px 0px;
		text-align: center;
		font-size: 50px;
		font-weight: bold;
		border-bottom: 4px solid #39cfba;
		padding: 8px 3px;
		margin-right: 20px;
		border-radius: 0px;
		color: #0e2c39;
		font-family: $font-condensed, sans-serif;

		@media screen and (max-width: 475px) {
			font-size: 32px;
			margin-right: 5px;
			max-width: 40px;
			height: 30px !important;
		}
	}
}

// google map styles

.google_map_Box {
	box-shadow: 0px 2px 6px #00000029;
	animation: maploading 0.3s ease-out;
	z-index: 10;
	background-color: #f4f4f4;
}

@keyframes maploading {
	0% {
		height: 0%;
	}

	20% {
		height: 40%;
	}

	60% {
		height: 60%;
	}

	80% {
		height: 100%;
	}
}

/*
--------------------------------------------------------------
 Form Checkbox styles
 --------------------------------------------------------------
*/

.checkbox-block {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	font-family: $font-light, sans-serif;
	font-weight: 500;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	// .checkbox-text {
	//     float: left;
	//     text-align: left;
	//     color: #0E2C39;
	//     padding: 2px 0px;
	// }

	// @media only screen and (max-width: 425px) {
	//     width: 100%;
	//     margin: 10px 0px;

	//     .checkbox-text {
	//         font-size: 18px;

	//     }
	// }
}

/* Hide the browser's default checkbox */
.checkbox-block input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: white;
	border: 1px solid #d2d1d1;
	//opacity: 15%;
}

/* On mouse-over, add a grey background color */
.form-checkbox:hover input ~ .checkmark {
	background-color: #f4f4f4;
}

/* When the checkbox is checked, add a blue background */
.checkbox-block input:checked ~ .checkmark {
	background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-block input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox-block .checkmark:after {
	left: 9px;
	top: 5px;
	width: 6px;
	height: 12px;
	border: solid #39cfba;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
