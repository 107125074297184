@import url('//db.onlinewebfonts.com/c/d72e521f27450184733d89aa499e531c?family=Helvetica+Neue+Condensed');
@import url('//db.onlinewebfonts.com/c/bb799fb846e98f21c468af59f4806875?family=HelveticaNeueLTStd-Lt');

@font-face {
	font-family: 'Helvetica Neue Thin';
	src: url('../fonts/Helvetica_Neue/HelveticaNeue-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica Neue Light';
	src: url('../fonts/Helvetica_Neue/HelveticaNeue\ Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica Neue Medium';
	src: url('../fonts/Helvetica_Neue/HelveticaNeue-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica Neue Bold';
	src: url('../fonts/Helvetica_Neue/Helvetica-Neu-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica Neue Condensed';
	src: url('../fonts/Helvetica_Neue/Helvetica-Condensed-Bold.ttf') format('truetype');
}

/*-------------------------ROBOTO --------------------------*/

@font-face {
	font-family: 'Roboto Thin';
	src: url('../fonts/roboto/Roboto-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Light';
	src: url('../fonts/roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Medium';
	src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Bold';
	src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../fonts/roboto/Roboto-Condensed-Bold.ttf') format('truetype');
}

/*

$font-thin: 'Helvetica Neue Thin';
$font-light: 'Helvetica Neue Light';
$font-medium: 'Helvetica Neue Medium';
$font-bold: 'Helvetica Neue Bold';
$font-condensed: 'Helvetica Neue Condensed';
*/

$font-thin: 'Roboto Thin';
$font-light: 'Roboto Light';
$font-medium: 'Roboto Medium';
$font-bold: 'Roboto Bold';
$font-condensed: 'Roboto Condensed';
